import React from "react"

import ContentContainer from "../../../../components/content-element/container"

import * as styles from "./component.module.less"

export default props => {
  return (
    <section className={styles.container}>
      <ContentContainer className={styles.inner}>
        <div className={`h2 ${styles.head}`}>
          Wählen Sie die Mitgliedschaft nach Ihrem Bedarf
        </div>

        <div className={`${styles.card} ${styles.first}`}>
          <div className={`h2 ${styles.header}`}>{props.header}</div>
          <div className={styles.benefits}>
            {props.benefits.map((m, i) => {
              return (
                <div key={i} className={styles.benefit}>
                  <div className={styles.check} />
                  {m}
                </div>
              )
            })}
          </div>
        </div>

        <div className={styles.img}>
          <img src={props.mainImage} alt="Gesundheitskarte" />
        </div>

        <div className={`${styles.card} ${styles.last}`}>
          <div className={styles.smallHeader}>Bkk Linde + Generali</div>
          <div className={styles.header}>Kooperations-Service</div>
          <div className={styles.benefits}>
            {props.coopBenefits.map((m, i) => {
              return (
                <div key={i} className={styles.benefit}>
                  <div className={styles.check} />
                  {m}
                </div>
              )
            })}
            <input type="checkbox" className={styles.checkbox} />
            <span>{props.checkBox} </span>
          </div>
          <div className={styles.plus} />
        </div>
      </ContentContainer>
    </section>
  )
}
