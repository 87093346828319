import React, { useRef, useState } from "react"

import PageHeader from "./my-insturance-features/header"
import Benefits from "./my-insturance-features/benefits"
import Calculator from "../../components/content-element/calculator-module"
import Questionnaire from "./my-insturance-features/questionnaire"
import moment from "moment"
import CardComparison from "./my-insturance-features/card_comparison"
import Cards from "../../../static/assets/cards2.png"
import * as styles from "./component.module.less"
const MyInsurance = ({ data }) => {
  const page = {
    seo: {
      title: "Meine Leistungen",
      description: "Meine Leistungen",
    },
  }

  const kkv = data.allDatoCmsKkv.nodes
  // beitragsmodul where gueltigAb is in past and gueltigBis is in future or gueltigBis is undefined
  const beitragsmodul = data.allDatoCmsBeitragsmodul.nodes.find(
    f =>
      (moment(new Date(f.gueltigAb)).isBefore(new Date()) &&
        moment(new Date(f.gueltigBis)).isAfter(new Date())) ||
      f.gueltigBis === null
  )

  const [selectedPhases, setSelectedPhases] = useState(null)
  const [benefits, setBenefits] = useState(null)
  const [age, setAge] = useState(0)
  const [questionnaireData, setQuestionnaireData] = useState({})
  const [informations, setInformations] = useState([{}])
  const [offerInformations, setOfferInformations] = useState({})
  const [phaseId, setPhaseId] = useState()
  const [kkvBonus, setKkvBonus] = useState(0)
  const [showDLButton, setShowDLButton] = useState(false)

  const calculatorRef = useRef()

  const phases = data.allDatoCmsLebensphasen.nodes.map(p => {
    p.altersgruppe = p.altersgruppe.map(a => {
      a.id = a.id.replace(/DatoCmsAltersgruppe-/, "")
      return a
    })
    p.versicherungsart = p.versicherungsart.map(v => {
      v.id = v.id.replace(/DatoCmsVersicherungsart-/, "")
      return v
    })
    p.mitgliedschaftsart = p.mitgliedschaftsart.map(m => {
      m.id = m.id.replace(/DatoCmsMitgliedschaftsarten-/, "")
      return m
    })
    p.score =
      (p.altersgruppe || []).length +
      (p.versicherungsart || []).length +
      (p.mitgliedschaftsart || []).length
    return p
  })

  const onQuestionnaireResult = result => {
    // age
    setAge(result.age.alt)

    setQuestionnaireData({
      customer: result.customer.value,
      occupation: result.occupation.value,
      age: result.age.alt,
      ageID: result.age.value,
      birthday: result.birthday.value,
    })

    // benefits
    const validPhases = phases
      .filter(phase => {
        // mitgliedschaftsart
        if (
          phase.mitgliedschaftsart.filter(m => m.id === result.customer.value)
            .length === 0
        )
          return false
        // altersgruppe
        if (
          phase.altersgruppe.filter(a => a.id === result.age.value).length === 0
        )
          return false
        // versicherungsart
        return (
          phase.versicherungsart.filter(m => m.id === result.occupation.value)
            .length > 0
        )
      })
      // sort by score, low to high
      .sort((a, b) => a.score - b.score)

    // select only 1 phase
    const selected = validPhases.length > 0 ? validPhases[0] : null
    if (!selected) {
      return
    }

    setPhaseId(selected.originalId)

    // reset calculators
    setBenefits(null)

    // set benefits after X milliseconds
    if (typeof window !== "undefined") {
      window.setTimeout(() => {
        setBenefits(selected ? selected.leistungen : [])
        if (process.env.GATSBY_DEBUG_INSURANCEFEATURES_PHASES === "1") {
          setSelectedPhases(validPhases.map(p => p.name))
        }
      }, 250)
    }
  }

  const onDownloadPdf = () => {
    let family = []
    if ((informations.length === 1) && (Object.keys(informations[0]).length === 0) && (kkvBonus > 0)) {
      family.push({
        kkvBonus: kkvBonus,
        bonus: 0,
        name: "Ich",
      })
    } else {
      family = informations.map(f => {
        return {
          kkvBonus: f.kkvBonus,
          bonus: f.bonus,
          name: f.name,
        }
      })
    }
    fetch(process.env.GATSBY_PDF_SERVICE + "pdf-vb/" + phaseId, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",

      },
      body: JSON.stringify({
        family: family,
        format: "file",
      }),
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.blob();
    })
    .then(blob => {
      // Handle the PDF blob
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = "Mein_Angebot_BKK_Linde.pdf"; // Name the file
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    }).catch(error => {
      console.error('There has been a problem with your fetch operation:', error);
    });
  }
  return (
    <>
      <PageHeader page={page} />

      <Questionnaire onResult={onQuestionnaireResult} />

      {benefits != null && (
        <>
          <Benefits
            items={benefits}
            onCalcClick={() => {
              if (!calculatorRef.current) return
              window.scrollTo({
                behavior: "smooth",
                top: calculatorRef.current.offsetTop + 30,
              })
            }}
          />

          {benefits.length > 0 && (
            <>
              <div ref={calculatorRef}>
                <Calculator
                  age={age}
                  beitragsmodul={beitragsmodul}
                  kkv={kkv}
                  insuranceType={data.allDatoCmsVersicherungsart.nodes}
                  onAllInformation={info => {
                    let temp = informations
                    let index = informations.findIndex(f => f.id === info.id)
                    if (index >= 0) {
                      temp[index] = info
                    } else {
                      temp.push(info)
                    }
                    setInformations(temp)
                    setShowDLButton(true);
                  }}
                  informations={informations}
                  questionnaireData={questionnaireData}
                  onKkvBonus={bonus => {setKkvBonus(bonus)}}
                />
              </div>
            </>
          )}
          {
            ((kkvBonus > 0) || (showDLButton)) && (
            <div className={styles.ctaDownload}>
              <div className="row">
                <div className="col-12">
                  <div className="text-center">
                    <button
                      className="btn btn-primary"
                      onClick={onDownloadPdf}
                    >
                      Angebot herunterladen
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )
          }
          <CardComparison
            benefits={[
              "Gesetzliche Krankenversicherung",
              "Extraleistung inklusive",
              "Digitaler Service",
            ]}
            header={"Mitgliedschaft"}
            coopBenefits={[
              "Gemeinsame Leistungsabrechnung",
              "Schnelle Abwicklung im Leistungsfall",
              "Vergünstigte Konditionen",
            ]}
            checkBox={
              "Ja, ich möchte Mitglied der BKK Linde werden und wünsche eine individuelle Beratung für eine Zusatzversicherung der Generali Krankenversicherung Deutschland AG."
            }
            button2={"Jetzt Mitglied werden"}
            mainImage={Cards}
          />
        </>
      )}
    </>
  )
}

export default MyInsurance;
