import React, { useEffect, useState } from "react"

import ContentContainer from "../../../../../components/content-element/container"

import * as styles from "./component.module.less"

export default props => {
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    if (typeof window === "undefined") {
      setWindowWidth(0)
    } else {
      setWindowWidth(window.innerWidth)
    }
  }, [window.innerWidth])

  return (
    <ContentContainer tag="section" className={styles.container}>
      <div className={styles.text}>
        Jetzt Mitglied werden und profitieren
        {/* vom FitBonus<sup>+</sup>  */}
      </div>
      <div className={styles.bonus}>
        {props.bonus
          .toFixed(2)
          .split(".")
          .join(",")}
        {" €"}
      </div>
    </ContentContainer>
  )
}
