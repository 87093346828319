// extracted by mini-css-extract-plugin
export var text = "component-module--text--2kMQY";
export var h1 = "component-module--h1--TpPMN";
export var h2 = "component-module--h2--1ExGw";
export var h3 = "component-module--h3--2x6im";
export var h4 = "component-module--h4--1OnAE";
export var container = "component-module--container--3xKtb";
export var inner = "component-module--inner--1XcZt";
export var head = "component-module--head--16yns";
export var card = "component-module--card--3pRB2";
export var header = "component-module--header--2qF9I";
export var button = "component-module--button--30uLU";
export var benefits = "component-module--benefits--18ClT";
export var benefit = "component-module--benefit--2ccIS";
export var check = "component-module--check--3Jz03";
export var checkbox = "component-module--checkbox--1A7PU";
export var img = "component-module--img--1Wd0w";
export var first = "component-module--first--K-NFI";
export var last = "component-module--last--3jwwp";
export var smallHeader = "component-module--smallHeader--2e_ty";
export var plus = "component-module--plus--3_RZr";