// extracted by mini-css-extract-plugin
export var header = "component-module--header--SYu65";
export var textWrap = "component-module--textWrap--3Iv47";
export var h4 = "component-module--h4--vX9kW";
export var bonusResult = "component-module--bonusResult--10E9X";
export var collapsed = "component-module--collapsed--DBWwA";
export var h = "component-module--h--2j71L";
export var bon = "component-module--bon--1Cj_W";
export var num = "component-module--num--s909p";
export var inner = "component-module--inner--13f-A";
export var noMargin = "component-module--noMargin--2Mt5W";
export var item = "component-module--item--_aL4H";
export var left = "component-module--left--7swD1";
export var selects = "component-module--selects--2TN_p";
export var selectWrap = "component-module--selectWrap--PIWKW";
export var input = "component-module--input--17cEL";
export var optionContainer = "component-module--option-container--3hO5o";
export var circle = "component-module--circle--2XMfa";
export var text = "component-module--text--1GQMg";
export var img = "component-module--img--2bu4m";
export var blue = "component-module--blue--WpfeF";
export var green = "component-module--green--3QiFn";
export var title = "component-module--title--3LYst";
export var rightEnd = "component-module--rightEnd--3vgq1";
export var selectWrapper = "component-module--selectWrapper--2djOG";
export var bonusCalculator = "component-module--bonusCalculator--2oXOQ";
export var h2 = "component-module--h2--2CspZ";
export var select = "component-module--select--2L7IV";
export var nextPerson = "component-module--nextPerson--2ociZ";
export var checkbox = "component-module--checkbox--2iWU-";
export var bmiContainer = "component-module--bmiContainer--3tEEw";
export var checkboxVac = "component-module--checkboxVac--2vGww";
export var headerContainer = "component-module--headerContainer--1Uzp0";
export var bonus = "component-module--bonus--2af1i";