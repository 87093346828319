// extracted by mini-css-extract-plugin
export var header = "component-module--header--q1GW-";
export var textWrap = "component-module--textWrap--g3lKI";
export var h2 = "component-module--h2--3hI52";
export var h4 = "component-module--h4--3SDo3";
export var bonusResult = "component-module--bonusResult--1Dnqm";
export var inner = "component-module--inner--3BZv1";
export var noMargin = "component-module--noMargin--1C5FD";
export var item = "component-module--item--1UmP0";
export var left = "component-module--left--1az8v";
export var selects = "component-module--selects--2PiL0";
export var selectWrap = "component-module--selectWrap--fL2do";
export var input = "component-module--input--2-BH7";
export var optionContainer = "component-module--option-container--2Psgw";
export var circle = "component-module--circle--15FgC";
export var text = "component-module--text--3Orwa";
export var img = "component-module--img--1r37R";
export var blue = "component-module--blue--1-GDC";
export var green = "component-module--green--10MwU";
export var title = "component-module--title--w8FCL";
export var footer = "component-module--footer--kAVMz";
export var btn = "component-module--btn--1usJN";
export var bmiContainer = "component-module--bmiContainer--12lkp";
export var checkboxBMI = "component-module--checkboxBMI--1ezRc";
export var checkboxVac = "component-module--checkboxVac--2iQQl";
export var checkboxV = "component-module--checkboxV--38pJK";
export var bonusCalculator = "component-module--bonusCalculator--3YWaM";
export var select = "component-module--select--3Rmdc";